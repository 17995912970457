import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { IconButton } from '@itsa.io/ui';
import darkmodeCtx from 'context/darkmode';
import { Close as CloseIcon } from '@material-ui/icons';
import LogoDark from 'assets/images/logo-dark.svg';
import LogoLight from 'assets/images/logo-light.svg';
import useStyles from 'styles/pages/LegalPage';

const LegalPage = props => {
	const classes = useStyles();
	const { darkmode } = useContext(darkmodeCtx);

	const handleClose = () => {
		navigate('/');
	};

	const logo = darkmode ? (
		<LogoDark className={classes.logo} />
	) : (
		<LogoLight className={classes.logo} />
	);

	const closeIcon = (
		<IconButton
			className={classes.closeButton}
			color="primary"
			onClick={handleClose}
		>
			<CloseIcon color="primary" />
		</IconButton>
	);

	let headerContent;
	if (!props?.location) {
		headerContent = (
			<header className={classes.header}>
				{closeIcon}
				{logo}
			</header>
		);
	}

	return (
		<div className={classes.root}>
			{headerContent}
			<article>
				<header className={classes.header}>
					<h1 className={classes.title}>Terms of Service</h1>
					<h3 className={classes.subTitle}>
						Read our terms below to learn more about your rights and
						responsibilities as an Itsawallet user.
					</h3>
				</header>
				<p>
					<i>Last Updated Feb 08, 2023</i>
				</p>
				<p>
					Welcome to Itsawallet, a free, client-side tool for interacting
					directly with the blockchain. Please read these terms of service
					(&#34;Terms of Service&#34;) carefully. They apply to your use of the
					Itsawallet (&#34;Itsawallet&#34;) promosites, Itsawallet browser
					applications, Itsawallet mobile applications and web browser extension
					(collectively, the &#34;Site&#34;) and the related services offered by
					Itsawallet through the Site.
				</p>
				<h2>1. General Conditions</h2>
				<p>
					The Terms of Service set forth the legally binding terms for your use
					of the Site. By using the Site, you agree to be bound by the Terms of
					Service, any additional posted guidelines or rules applicable to
					specific services and features, and our{' '}
					<a href="/privacy-policy/" target="_blank" rel="noopener noreferrer">
						Privacy Policy
					</a>{' '}
					at https://app.itsawallet.finance/privacy-policy (together, the
					&#34;Agreement&#34;). If you are accepting the Terms of Service on
					behalf of a company or other legal entity, you represent and warrant
					that you have the authority to bind such entity to the terms set forth
					herein. If you do not have such authority or do not agree to be bound
					by the Terms of Service, you may not access or use the Site. You must
					agree to the Terms of Service and Privacy Policy when you create a
					wallet via the Site, perform a transaction via the Site, and/or
					otherwise use the Site.
				</p>
				<p>
					THESE TERMS OF SERVICE CONTAIN IMPORTANT INFORMATION REGARDING YOUR
					LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. YOU AND Itsawallet AGREE THAT
					DISPUTES BETWEEN US WILL BE RESOLVED BY MANDATORY BINDING ARBITRATION,
					AND YOU WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR
					CLASS ARBITRATION.
				</p>
				<p>
					Itsawallet may, in its sole discretion, modify or revise the Terms of
					Service at any time, and you agree to be bound by such modifications
					or revisions. Although we may attempt to notify you when major changes
					are made to the Terms of Service, you should periodically review the
					most up-to-date version, which will always be posted at the Site. Your
					continued use of the Site constitutes your acceptance of such changes.
				</p>
				<h2>2. Services Eligibility And Information</h2>
				<p>
					<b>2.1 Services Eligibility</b>
				</p>
				<p>
					The Site is offered and available to users who are 18 years of age or
					older. Any use of or access to the Site by anyone under 18 is
					unauthorized and in violation of these Terms of Service. By using the
					Site, you represent and warrant that you are 18 years of age or older
					and that you agree to abide by the Terms of Service.
				</p>
				<p>
					<b>2.2 Services Information and Limitations</b>
				</p>
				<p>
					The Site is a free, client-side interface that allows you to interact
					directly with the blockchain, while remaining in full control of your
					keys and assets.
				</p>
				<p>
					When you access certain features of the Site, you will be able to
					access a wallet to perform a variety of transactions and actions. You
					do not and will not create an account using Itsawallet. You also will
					not give us any of your assets, and your tokens and coins are not on
					Itsawallet. All assets are on the blockchain itself, and we do not
					control them. Itsawallet does not collect or hold your keys or account
					information, and Itsawallet cannot access accounts; recover keys,
					passwords, or other information; reset passwords; or reverse
					transactions. You are solely responsible for your use of the Site,
					including without limitation for storing, backing-up, and maintaining
					the confidentiality of your keys, passwords, and information, and for
					the security of any transactions you perform using the Site. You
					expressly relieve and release Itsawallet from any and all liability
					and/or loss arising from your use of the Site.
				</p>
				<p>
					Users interacting with digital assets as investments should be aware
					that all investments involve risks, including the risk of loss of some
					or all assets. Losses are not insured, and the user assumes
					responsibility for all losses. Users are advised to exercise caution,
					conduct research, and not to transact more than they can afford to
					lose.
				</p>
				<p>
					Prior to using the Site for any purpose, we highly recommend that you
					read our guides, &#34;Itsawallet: An Introduction,&#34; and
					&#34;Pro-Tips: How to Avoid Phishing/Scams&#34; on for some
					recommendations on how to be proactive about your security. In
					addition, we recommend that you review the additional FAQs, tips,
					guidelines and YouTube videos provided on the Site.
				</p>
				<h2>3. Rights And Restrictions</h2>
				<p>
					<b>3.1 Our Proprietary Rights</b>
				</p>
				<p>
					Our services are protected by copyright, trademark, and other
					international laws. Except as expressly provided in these Terms, we
					exclusively own all right, title and interest in and to the Site,
					including all associated intellectual property rights. You may not
					remove, alter or obscure any copyright, trademark, service mark or
					other proprietary rights notices incorporated in or accompanying
					services, including in any Content. You acknowledge and agree that any
					feedback, comments or suggestions you may provide regarding the Site
					will be the sole and exclusive property of Itsawallet, and you hereby
					irrevocably assign to us all of your right, title and interest in and
					to the foregoing. Itsawallet reserves the right to discontinue any
					aspect of the Site at any time.
				</p>
				<p>
					<b>3.2 Restrictions</b>
				</p>
				<p>
					You agree not to (a) interfere with, damage, impair, or disable the
					Site&apos;s operation, by any means (whether through automated means
					or otherwise), including uploading or otherwise disseminating viruses,
					worms, spyware, adware or other malicious code; (b) use any robot,
					spider, scraper, or other automated means to access the Site for any
					purpose without our express consent or bypass our robot exclusion
					headers or similar measures; (c) remove, circumvent, disable, damage
					or otherwise interfere with the Site&apos;s security-related features,
					features that prevent or restrict the use or copying of any part of
					the Site, or features that enforce limitations of the Site; (d) take
					any action that imposes, or may impose, in our sole discretion, an
					unreasonable or disproportionately large load on our technology
					infrastructure or otherwise make excessive traffic demands of the
					Site; (e) use the Site for any illegal or unauthorized purpose nor may
					you, in the use of the Site, violate any laws in your jurisdiction
					(including but not limited to intellectual property laws); or (f) use,
					including as part of trademarks and/or as part of domain names, in
					connection with any product or service in any manner that is likely to
					cause confusion and may not be copied, imitated, or used, in whole or
					in part, without the prior written permission of Itsawallet.
				</p>
				<h2>4. Privacy</h2>
				<p>
					Itsawallet’s Privacy Policy applies to the collection and use of
					&#34;Personal Information,&#34; which is defined as information about
					an individual that (either by itself or when combined with information
					from other available sources) allows that individual to be identified.
					Itsawallet’s Privacy Policy is available at
					&#34;https://www.itsawallet.finance/privacy-policy&#34; and its terms
					are made a part of these Terms of Service by this reference.
				</p>
				<h2>5. Third Party Content And Services</h2>
				<p>
					The Site may contain links to third-party websites and/or
					applications, or employ the use of third-party smart contracts that
					are not owned or controlled by Itsawallet, such as the third-party
					smart contracts of decentralized exchanges like Uniswap. Access to and
					use of any third-party smart contracts or applications is at your own
					risk and we are not responsible for (a) the accuracy or reliability of
					information of third-party smart contracts or applications; (b) the
					acts or omissions of the third-party smart contracts or applications;
					(c) any loss or damage incurred by interacting with any of the
					third-party smart contracts or applications. We encourage you to be
					aware when you make a transaction using these third-party smart
					contracts or applications, and to read the terms and privacy policy of
					each other&apos;s third-party smart contracts or applications that you
					utilize. Any links we may provide to these services is merely as a
					convenience, and the inclusion of such links and integrations does not
					imply an endorsement. Upon leaving the Site, these Terms of Service
					shall no longer govern. By using the Site, you expressly relieve and
					release Itsawallet from any and all liability arising from your use of
					any Third-party Applications.
				</p>
				<h2>6. Subscription Plans</h2>
				<p>
					Some features of Itsawallet are available through a paid subscription
					plan (&#34;Subscription&#34;) or by obtaining an Itsawallet-NFT
					(&#34;NFT&#34;). Both, Subscription and NFT, belong to the account
					that purchased them and therefore the extra features ONLY APPLY to
					this account. However, a Subscription and some types of NFT can bind -
					and with that expose these same extra features - to other accounts.
				</p>
				<p>
					<b>6.1 Subscription</b>
				</p>
				<p>
					A Subscription can be purchased through Itswallet by calling the
					Subscription smart contract. Each Subscription has a specific
					operational period. After the Subscription expires, the Subscription
					will end and you will not have the extra features that come with the
					Subscription anymore.
				</p>
				<p>
					Subscriptions can be extended at any time. If a Subscription is
					extended while still valid, the extra time will be added to the
					current Subscription.
				</p>
				<p>
					A maximum of 5 accounts can be added to the Subscription. The extra
					features of the Subscription will also apply to these accounts.
				</p>
				<p>
					<b>6.2 NFT</b>
				</p>
				<p>
					An NFT exposes extra features for a lifetime. An NFT might be bought
					from within Itsawallet, or at https://opensea.io. This means that it
					can be sold on the open Market. The transfer of ownership will result
					in loss of extra features for the current holder.
				</p>
				<p>
					Itsawallet has different types of NFTs, which may expose different
					extra features, or a different amount of extra accounts that can be
					bound to the NFT. Accounts that are bound with a NFT will also have
					the same extra features exposed as its NFT, until the bound is made
					undone.
				</p>
				<p>
					Should an NFT be transferred to another account, the list of bound
					accounts will be cleared. This means that both the former holder of
					the NFT and the former bound accounts will no longer have the extra
					features.
				</p>
				<p>
					<b>6.3 Free Trial</b>
				</p>
				<p>
					Itsawallet offers a free trial to the Subscription for a limited time
					(&#34;Trial&#34;). A Trial can be obtained through Itswallet by
					calling the Subscription smart contract.
				</p>
				<p>Every account can get a Trial only once.</p>
				<p>
					During the time of a Trial, all Subscription’s extra features are
					available. When the Trial ends, the user will lose these extra
					features and will have to either buy a Subscription or an NFT, should
					you want to continue making use of these extra features.
				</p>
				<p>
					<b>6.4 Fee Changes</b>
				</p>
				<p>
					Itsawallet may change the Subscription fees for its plans on a regular
					basis at its sole discretion. Any changes to the Subscription fees
					will take effect for new Subscriptions or Subscription extension in
					terms of time validity.
				</p>
				<p>
					<b>6.5 Refunds</b>
				</p>
				<p>
					Unless required by law, paid Subscription fees are non-refundable.
				</p>
				<h2>7. Disclaimers And Limitations On Liability</h2>
				<p>
					YOUR USE OF THE SITE, ITS CONTENT AND ANY SERVICES OFFERED THROUGH THE
					SITE IS AT YOUR OWN RISK. THE SITE, ITS CONTENT AND ANY SERVICES
					OFFERED THROUGH THE SITE ARE PROVIDED ON AN &#34;AS IS&#34; AND
					&#34;AS AVAILABLE&#34; BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
					EITHER EXPRESS OR IMPLIED. NEITHER Itsawallet NOR ANY PERSON OR ENTITY
					ASSOCIATED WITH Itsawallet MAKES ANY WARRANTY OR REPRESENTATION WITH
					RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY
					OR AVAILABILITY OF THE SITE OR THE SERVICES. WITHOUT LIMITING THE
					FOREGOING, NEITHER Itsawallet NOR ANY PERSON OR ENTITY ASSOCIATED WITH
					Itsawallet REPRESENTS OR WARRANTS THAT THE SITE, ITS CONTENT OR ANY
					SERVICES OFFERED THROUGH THE SITE WILL BE ACCURATE, RELIABLE,
					ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE
					SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR
					OTHER HARMFUL COMPONENTS OR THAT THE SITE OR ANY SERVICES OFFERED
					THROUGH THE SITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
				</p>
				<p>
					Itsawallet HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
					EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED
					TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR
					PARTICULAR PURPOSE.
				</p>
				<p>
					IN NO EVENT WILL Itsawallet, ITS AFFILIATES, SERVICE PROVIDERS,
					EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY
					KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH
					YOUR USE, OR INABILITY TO USE, THE SITE, ANY WEBSITES LINKED TO IT,
					ANY CONTENT ON THE SITE OR SUCH OTHER WEBSITES, OR ANY SERVICES
					OFFERED THROUGH THE SITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT,
					INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES,
					INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
					EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
					OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
					AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT
					OR OTHERWISE, EVEN IF FORESEEABLE.
				</p>
				<p>
					The limitations and disclaimers in this section do not purport to
					limit liability or alter your rights beyond what is permitted by
					applicable law. Itsawallet’s liability shall be limited to the extent
					permitted by law.
				</p>
				<p>
					Access to the Site may not be legal by certain persons or in certain
					countries. You access the Site on your own initiative and are
					responsible for compliance with local laws. The Site may have been
					translated into a variety of languages. However, Itsawallet can only
					verify the validity and accuracy of the information provided in
					English and, because of this, the English version of the Site is the
					official text.
				</p>
				<h2>8. Indemnity</h2>
				<p>
					You agree to defend, indemnify and hold harmless Itsawallet, its
					officers, directors, employees and agents from and against any and all
					claims, damages, obligations, losses, liabilities, costs or debt, and
					expenses (including but not limited to attorneys’ fees) arising from
					your use of and access to the Site, or your violation of these Terms
					of Service. This defense and indemnification obligation will survive
					these Terms of Service and your use of the Site.
				</p>
				<h2>9. Assignment</h2>
				<p>
					These Terms of Service, and any rights and licenses granted hereunder,
					may not be transferred or assigned by you, but may be assigned by
					Itsawallet without restriction.
				</p>
				<h2>10. Entire Agreement</h2>
				<p>
					The Terms of Service, Privacy Policy, any other legal notices
					published by Itsawallet on the Site shall constitute the entire
					agreement between you and Itsawallet concerning the Site. If any
					provision of these Terms of Service is deemed invalid by a court of
					competent jurisdiction, the invalidity of such provision shall not
					affect the validity of the remaining provisions of these Terms of
					Service, which shall remain in full force and effect. No waiver of any
					term of these Terms of Service shall be deemed a further or continuing
					waiver of such term or any other term, and Itsawallet’s failure to
					assert any right or provision under these Terms of Service shall not
					constitute a waiver of such right or provision.
				</p>
				<h2>Contact Information</h2>
				<p>
					We welcome your comments or questions about these Terms of Service.
					You may contact us at info@itsawallet.finance.
				</p>
			</article>
		</div>
	);
};

LegalPage.defaultProps = {
	location: null,
};

LegalPage.propTypes = {
	location: PropTypes.object,
};

export default LegalPage;
